import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/housing-housing-affordability/housing-housing-affordability-hero.webp'
import accomplishment1 from '../../images/issues/housing-housing-affordability/housing-housing-affordability-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/housing-housing-affordability/housing-housing-affordability-accomplishment-2.webp'
import accomplishment3 from '../../images/issues/housing-housing-affordability/housing-housing-affordability-accomplishment-3.webp'
import accomplishment4 from '../../images/issues/housing-housing-affordability/housing-housing-affordability-accomplishment-4.webp'
import accomplishment5 from '../../images/issues/housing-housing-affordability/housing-housing-affordability-accomplishment-5.webp'


let issueDetailPageHero = 'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'
let accomplishmentImgPlaceholder = 'http://placehold.it/502x282'

const IssuesHousingHousingAffordabilityPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Housing &amp; Housing Affordability" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl">Housing</h1>
          <p className="text-2xl leading-relaxed">
            Local government with the support of federal government has many responsibilities to the people living in communities, but managing smart development projects with a focus on resiliency and equity tops the list. Currently, the decisions made about new development projects hide behind inaccessible and opaque discourse. A more transparent process improves accountability, prevents special interests and loopholes, and allows for a more effective and interactive discussion on true community benefits. Today's crises of inequality, lack of affordability, environmental issues, and traffic congestion need a new approach. John seeks to reform the planning and development processes to usher in smart development all while protecting the historic vibrancy and nuances of each neighborhood in Rhode Island.
          </p>
          <p className="text-2xl leading-relaxed">
            John supports the <strong><a href="https://www.warren.senate.gov/imo/media/doc/American%20Housing%20and%20Economic%20Mobility%20Act%20Summary%20116th%20Congress%20(003).pdf" target="_blank">American Housing and Economic Mobility Act</a></strong> and <strong><a href="https://democrats-financialservices.house.gov/uploadedfiles/2023_housing_supply_bill_fs.pdf" target="_blank">The Housing Crisis Response Act of 2023</a></strong>, which would level the uneven American housing market playing field and ensure that ALL people, both renters and buyers, can access affordable housing.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Advocated for Smart Development &amp; Housing Affordability</h3>
              <p>• Supported community-oriented development that helped address our housing crisis such as Providence's Parcel 9, which is a mixed-use development of 131 units including 41% affordable housing, 18% workforce housing, and 41% market-rate housing with a focus on sustainability. Additionally, in an effort to advocate for local families, we introduced the developer to local childcare providers and urged the developer to use retail space to serve the community, which will result in a planned childcare space that will serve approximately 50 families.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Passed Legislation Calling For The City Planning Commission (CPC) To Conduct Periodic Workshops On Zoning Practices &amp; Procedures</h3>
              <p>• Lead sponsor on passed legislation calling for the City Planning Commission (CPC) to conduct periodic workshops on zoning practices and procedures. The goal of this is to expand engagement and to make city planning more accessible and transparent. Furthermore, this is to ensure our neighborhood residents have a better understanding and voice in zoning-related matters that impact us. Read more <a href="https://pbn.com/providence-council-requests-periodic-workshops-for-public-on-zoning-practices/" target="_blank">here</a> and <a href="https://council.providenceri.gov/2020/10/15/councilman-john-goncalves-proposes-and-city-council-passes-resolution-requesting-public-workshops-on-city-zoning-practices/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Passed Ordinance Expanding the College Hill Historic District Overlay</h3>
              <p>• Lead sponsor on a passed ordinance expanding the College Hill Historic District Overlay by close to 90 properties to protect the historic vibrance and fabric of our beautiful neighborhood. This law codifies historic preservation and prevents the undermining or demolition of historic homes. Read more <a href="https://www.browndailyherald.com/article/2021/07/college-hill-historic-district-expansion-passes-despite-prior-university-objections/" target="_blank">here</a>, <a href="https://council.providenceri.gov/2021/06/21/providence-city-council-expands-college-hill-historic-district/" target="_blank">here</a>, and <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12838&MediaPosition=&ID=30875&CssClass=" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Passed Ordinance Requiring Notification Upon The Issuance Of A Demolition Permit</h3>
              <p>• Lead sponsor on a passed ordinance requiring notification upon the issuance of a demolition permit and holding developers accountable for failure to do so. This law will result in our neighborhood from being blindsided by demolitions as we experienced with the Duck &amp; Bunny in Fox Point. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12834&MediaPosition=&ID=31509&CssClass=" target="_blank">here</a> and <a href="https://pbn.com/razing-of-duck-bunny-building-spurs-calls-for-new-requirements-on-demolitions/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Put The Community First &amp; Fought Tirelessly To Check Brown University Expansion</h3>
              <p>• Led the advocacy to over 1,100 neighborhood residents to ensure that Brown made changes to its Brook Street dorm development. While Brown didn't entirely concede, we held them accountable to the neighborhood and pushed the University to make critical concessions. Additionally, we called on for Brown to alleviate more taxation burden on East Side/city residents and do more for the City of Providence.  Read more <a href="https://www.bostonglobe.com/2021/11/03/metro/brown-university-can-should-do-more-people-providence/" target="_blank">here</a>, <a href="https://council.providenceri.gov/2021/07/12/joint-statement-from-councilman-john-goncalves-and-councilwoman-helen-anthony-regarding-brown-universitys-dormitory-development/" target="_blank">here</a>, <a href="" target="_blank">here</a>, and <a href="" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment5}
                alt=""
              />
              <aside className="text-xs">Lane Turner / The Boston Globe via Getty Images file</aside>
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl">The opportunity to afford a safe and healthy home is a right that not all Rhode Island residents have at this time. Unfortunately, many do not have accessible, affordable housing, and many struggle with their monthly rent payments. The eviction crisis that has spread across the country due to COVID-19 economic impacts has settled in Rhode Island in a serious way. Families should not have to live in fear of eviction due to circumstances outside their control. When it comes to affordable housing and access, John plans to go after resources to construct affordable living communities and reduce homelessness. These initiatives will include zoning reforms, community land trusts, and other efforts to create long-term positive effects for individuals and families.</p>

          <h3 className="font-bold text-3xl">Fighting the Post-COVID-19 Pandemic Housing Crisis</h3>
          <p className="text-xl">The unprecedented circumstances surrounding the COVID-19 pandemic have led to increased struggles when it comes to maintaining housing for many people in the state. The looming eviction and foreclosure crisis spelled disaster for the overall economic, education, health, and safety situations of Rhode Island residents. We must work quickly to offer sufficient support to families facing housing loss. Rental relief and other programs must be put into place for the good of our communities and future.</p>

          <h3 className="font-bold text-3xl">Planning Safe Communities With Resilience</h3>
          <p className="text-xl">A focus on resiliency helps to ensure future success for Rhode Island and its families. The looming housing crisis points to large-scale displacement that would negatively affect the state for a long time to come. The creation of a strong planning must include plans to maintain a residence here for people with disabilities and seniors. John's ideas for repairing and revitalizing the broken residential development system will bring about these essential changes.</p>

          <h3 className="font-bold text-3xl">Protecting Tenants With a Stable Rental Market</h3>
          <p className="text-xl">The rental market in Rhode Island and across the country suffers due to the COVID-19 pandemic. Tenants in the state struggle with worry and uncertainty about their future housing. Action focused on stabilizing the rental market for short and long-term tenants is an essential part of the type of protection that Rhode Island must adopt. This includes a right to seek counsel for legal issues. The time has come to close commercial loopholes so renters feel secure against potential displacement, increasing prices, and public safety concerns.</p>

          <h3 className="font-bold text-3xl">Finding Solutions for Homelessness</h3>
          <p className="text-xl">Bottom line, as a state, we struggle with temporary and chronic homelessness and need solutions that lead to long-term, accessible, and helpful housing options. Proper housing affects all public health issues and community problems in Rhode Island. Investment and coordination of strong partnerships with a variety of services including addiction disorder treatment, mental health care, support for those with disabilities, and overall focus on assistance for anyone currently in an unstable housing situation.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesHousingHousingAffordabilityPage